@import "variables";
@import "mixins";

.product-comparison {
  .content {
    .product-tile {
      max-width: 350px;
      margin-left: auto;
      margin-right: auto;
      .title-tab {
        @include border-radius(6px 6px 0 0);
        text-align: center;
        background-color: #60A4BC;
        margin: -$tile-padding;
        margin-bottom: 0;
        padding: $tile-padding $tile-padding 0 $tile-padding;
        position: relative;
        color: white;
        &.drone {
          background-color: #00A777;
          &:after {
            background-color: #00A777;
          }
        }
        &:after {
          @include border-radius(50%);
          content: "";
          display: block;
          position: absolute;
          left: 0;
          bottom: -15px;
          right: 0;
          width: 100%;
          height: 30px;
          background-color: #60A4BC;
          z-index: 10;
        }
        img {
          margin-bottom: 3px;
          height: 63px;
          width: auto;
        }
        .caption {
          color: white;
          margin-bottom: 0;
          position: relative;
          z-index: 100;
        }
        h4 {
          margin: 0 0 5px 0;
          position: relative;
          z-index: 100;
        }
      }
      .feature-body {
        margin-top: 25px;
        .feature {
          margin: 8px (-$tile-padding);
          padding: 0 $tile-padding;
          text-align: center;
          h5 {
            margin: 0;
          }
        }
        .feature:nth-child(even) {
          background-color: #E6EAEC;
          padding: 10px $tile-padding;
        }
        .feature:last-child {
          margin-bottom: 0;
        }
      }
      .price-and-cta {
        width: 100%;
        .price {
          @include border-radius(2px);
          margin: 30px 0 0 0;
          text-align: center;
          .caption {
            margin: 3px 0 0 0;
          }
          strong {
            font-size: 1.3em;
          }
        }
        .cta {
          width: 100%;
          margin-top: 10px;
        }
      }
    }
  }
}
@media only screen and (min-width: 601px) {
  .product-comparison {
    .content > .row,
    .content > .row > .col {
      display: flex;
    }
    .container {
      width: 80%;
      max-width: 1000px;
      .product-tile {
        .feature-body {
          padding-bottom: 135px;
        }
        .price-and-cta {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          padding: $tile-padding;
          padding-top: 0;
        }
      }
    }

  }
}
