@import "variables";

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin box-shadow-custom($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  -ms-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin tile-box-shadow {
  -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .3);
  -moz-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .3);
  -ms-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .3);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .3);
}

@mixin transition($sec) {
  -webkit-transition: all ease ($sec);
  -moz-transition: all ease ($sec);
  -ms-transition: all ease ($sec);
  transition: all ease ($sec);
}

@mixin rotate($deg) {
  -ms-transform: rotate($deg);
  -webkit-transform: rotate($deg);
  transform: rotate($deg);
  -moz-transform: rotate($deg);
}

@mixin opacity($opacity) {
  -moz-opacity: ($opacity);
  -khtml-opacity: ($opacity);
  opacity: ($opacity);
}

@mixin centralise() {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@mixin visible-scrollbar() {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}

@mixin scewed-edge($background-color) {
  background-color: $background-color;
  position: relative;
  z-index: 1;
  &:after {
    background: inherit;
    bottom: 0;
    content: '';
    display: block;
    height: 100px;
    left: 0;
    position: absolute;
    right: 0;
    transform: skewY(-2deg);
    transform-origin: 100%;
    z-index: -1;
  }
}

@mixin flip($transform) {
  /*e.g. transform: scaleX(-1);*/
  -moz-transform: $transform;
  -o-transform: $transform;
  -webkit-transform: $transform;
  transform: $transform;
}
